export const Colors = {
  black: '#1b1f30',
  blue: '#4f7cf7',
  darkBlue: '#4268D0',
  blueBackground: 'rgba(79, 123, 247, 0.08)',
  blueBackgroundMiddle: 'rgba(79, 123, 247, 0.16)',
  blueBackgroundDark: 'rgba(79, 123, 247, 0.2)',
  blueBorder: 'rgba(56, 108, 255, 0.3)',
  blueDarkBorder: 'rgba(56, 108, 255, 0.5)',
  blueMiddle: 'rgba(56, 108, 255, 0.7)',
  green: 'rgba(79, 247, 123, 0.6)',
  grey: 'rgba(21, 23, 36, 0.8)',
  middleGrey: 'rgba(21, 23, 36, 0.7)',
  middleLightGrey: 'rgba(21, 23, 36, 0.6)',
  lightGrey: 'rgba(21, 23, 36, 0.4)',
  lightlightGrey: 'rgba(20, 22, 36, 0.2)',
  backgroundGrey: 'rgba(20, 22, 36, 0.06)',
  greyish: 'rgb(253, 253, 253)',
  red: '#ff5252',
  borderGrey: '#EBEBEB'
};
